import cart from '@/lib/cart'
import state from '@/lib/appState'

document.addEventListener('DOMContentLoaded', () => {
  const addFirstFreeGwp = (variantId) => {
    cart.add([{
      quantity: 1,
      id: variantId,
      properties: {
        _gwp_nc: '1'
      }
    }]).then((res) => {
      console.log('Added Free GWP: ', res)
      cart.get().then((data) => {
        state.cart = data
      })
    })
  }

  const removeLineItem = async (variantId) => {
    await cart.update([{
      quantity: 0,
      id: String(variantId),
    }]).then((res) => {
      console.log('Remove LineItem: ', res);
      cart.get().then((data) => {
        state.cart = data
      })
    })
  }

  const updateGiftGwp = () => {
    window
    .fetch(`/cart?view=ajax`)
    .then(response => response.json())
    .then(data => {
      window.BARREL.checkoutData = data.cartData
      window.BARREL.gwp = data.gwpSettings
      window.BARREL.firstGwp = data.firstGwp
      const isCustomerEligibleForFirstFreeGwp = window.BARREL.customer.total_spent === 0 && window.BARREL.customer.id
      const lineItemFirstGWP = window.BARREL.checkoutData ? window.BARREL.checkoutData.lineItems.find(item => item?.properties?._gwp_nc === '1') : false
      const lineItemGWP = window.BARREL.checkoutData ? window.BARREL.checkoutData.lineItems.find(item => item?.properties?._gift_for_purchase === 'true') : false

      if ((lineItemGWP && !window.BARREL.customer.id) || (lineItemGWP && window.BARREL.checkoutData.lineItems.length === 1)) {
        removeLineItem(lineItemGWP.variant_id)
      }

      if ((lineItemFirstGWP && window.BARREL.checkoutData.lineItems.length === 1) || (lineItemFirstGWP && !isCustomerEligibleForFirstFreeGwp) || (lineItemGWP && lineItemFirstGWP)) {
        removeLineItem(lineItemFirstGWP.variant_id)
      }

      if (isCustomerEligibleForFirstFreeGwp && window.BARREL.checkoutSettings.enableForFirst && window.BARREL.firstGwp && !lineItemFirstGWP && !lineItemGWP && window.BARREL.checkoutData.lineItems.length > 0) {
        addFirstFreeGwp(window.BARREL.firstGwp.id)
      }
  
      if (lineItemFirstGWP && window.BARREL.customer.id && window.BARREL.customer.total_spent > 0) {
        removeLineItem(lineItemFirstGWP.variant_id)
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  setTimeout(() => {
    updateGiftGwp()
  }, 200)

  document.body.addEventListener('update-gwp', () => {
    setTimeout(() => {
      updateGiftGwp()
    }, 300)
  })
})

